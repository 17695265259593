import Card from 'react-bootstrap/Card';

export const Planos = () => {
  return (
    <section>
      <h2 className='text-center pt-1 fs-2'>PLANOS</h2>
      <div className="planos" id="planos">
        <>
          {Array.from({ length: 5 }, (_, index) => {
            const variant = index % 2 === 0 ? 'Success' : 'Dark';
            const cardContents = [
              {
                title: 'PESSOAL',
                text: '1 usuário: ',
                text1: 'Até 03 contas bancárias:'
              },
              {
                title: 'PROFISSIONAL',
                text: 'Até 3 usuários: ',
                text1: 'Até 05 contas bancárias:',
                text2: 'Inclui módulo Nota Fiscal, Estoque e Vendas:'
              },
              {
                title: 'EMPRESARIAL',
                text: 'Até 05 usuários: ',
                text1: 'Até 07 contas bancárias:',
                text2: 'Inclui módulo Nota Fiscal, Estoque e Vendas:'
              },
              {
                title: 'CORPORATIVO',
                text: 'Até 12 usuários: ',
                text1: 'Até 12 contas bancárias:',
                text2: 'Inclui módulo Nota Fiscal, Estoque, Vendas e Integrações com Sites E-commerce.'
              },
              {
                title: 'PERSONALIZADO',
                text: 'Projeto fora dos planos padrões, a proposta de valores será estudado e montado pelo Gestor de contas e Analista Financeiro.'
              },
            ];

            const { title, text, text1, text2 } = cardContents[index % cardContents.length];

            return (
              <Card
                bg={variant.toLowerCase()}
                key={index}
                text={variant.toLowerCase() === 'light' ? 'dark' : 'white'}
                style={{ width: '16rem' }}
                className="mb-2 m-2"
              >
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>
                    {text}
                    <br />
                    <br />
                    {text1}
                    <br />
                    <br />
                    {text2}
                  </Card.Text>
                </Card.Body>
              </Card>
            );
          })}
        </>
      </div>
    </section>
  )
}
