import { Container, Row, Col } from "react-bootstrap";
import logo from "../logo.png"
import localizacao from "../assets/img/placeholder1.webp"
import contato from "../assets/img/contato.webp"

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="contact-container ">
          <Row className="align-items-center row-footer">
            <Col size={12} sm={6} className="footer-logo" >
              <img src={logo} alt="Logo" className="logo-footer" />
            </Col>

            <Col className="d-flex flex-column align-items-center justify-items-center">
              <img src={contato} alt="" style={{ width: '2.5rem' }} />
              <p href="">+55 47 9271-1199 <br /> +55 47 3255-5544</p>
            </Col>

            <Col className="d-flex flex-column align-items-center justify-items-center">
              <img src={localizacao} alt="" style={{ width: '2.5rem' }} /> <br />
              <a href="https://maps.google.com?q=R.+Augusto+Maluche,+48+-+4º+andar+-+Centro+1,+Brusque+-+SC,+88354-015" target="_blank" rel=" noreferrer">R. Augusto Maluche, 48 - 4º andar - Centro 1 Brusque - SC 88354-015 </a>
            </Col>

            {/* <Col size={1} sm={2} className="text-center text-sm-end">
               <div className="social-icon">
                 <a href="#">
                  <img src={navIcon1} alt="Icon" />
                </a>
                <a href="#">
                  <img src={navIcon2} alt="Icon" />
                </a>
                <a href="https://www.instagram.com/vialucro/" target="_blank" rel=" noreferrer">
                  <img src={navIcon3} alt="Icon" />
                </a>
              </div> 
            </Col>*/}
            
          </Row>
        </div>

        <Row>
          <Col><p className="m-1" id="copyright">Copyright 2024 MobileWay. All Rights Reserved</p></Col>
        </Row>
      </Container>
    </footer>
  );
}
