import React from 'react'
import whatsIcon from "../assets/img/whatsapp.webp";
import TrackVisibility from 'react-on-screen';

export const Whats = () => {
  return (
    <div>
      <a href='https://wa.me/554796138035' className='whatsButton' target="_blank" rel=" noreferrer">
        <img src={whatsIcon} alt="icon" style={{ width: '4rem' }} />
      </a>
    </div>
  )
}

<TrackVisibility>
  {({ isVisible }) =>
    <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
      <a href='https://wa.me/554796138035' className='whatsButton' target="_blank" rel=" noreferrer">
        <img src={whatsIcon} alt="Whatsapp icone" />
      </a>
    </div>}
</TrackVisibility>