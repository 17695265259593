import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Planos } from "./components/Planos";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Whats } from './components/Whats';
import { Vantagens } from './components/Vantagens';
import { Funcionalidades } from './components/Funcionalidades';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Whats />
      <Banner />
      <Vantagens />
      <Funcionalidades />
      <Planos />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
