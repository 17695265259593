import { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, ListGroup } from 'react-bootstrap';
import financeiro from "../assets/img/financeiro.webp"
import estoque from "../assets/img/estoque.webp"
import vendas from "../assets/img/vendas.webp"
import relatorios from "../assets/img/relatorios.webp"

export const Funcionalidades = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState([]);

    const handleShowModal = (content) => {
        setModalContent(content);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <section className='funcionalidades' id='funcionalidades'>
            <Container>
                <h3 className='text-center p-3 fs-2'>FUNCIONALIDADES</h3>
                <Row className="justify-content-center align-items-center p-3 ">

                    <Col xs={3} className="text-center funcionalidades-cards">
                        <Card style={{ width: '18rem', maxHeight: '400px', height: '100%', minHeight: '400px' }} className="custom-border">
                            <Card.Img variant="top" src={financeiro} style={{ width: '4rem', }} className='mx-auto m-2' />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title>BPO Financeiro</Card.Title>
                                <Card.Text id='text-black'>
                                    Terceirização de tarefas financeiras para especialistas, visando a eficiência e a redução de custos.
                                </Card.Text>
                                <Button variant="primary" className="mt-auto" onClick={() => handleShowModal([
                                    <ul className='fs-5'>
                                        <li>Contas a Pagar</li>
                                        <li>Contas a Receber</li>
                                        <li>Movimentos Bancario</li>
                                        <li>Fluxo de Caixa</li>
                                        <li>Conciliao Bancaria</li>
                                    </ul>
                                ])}>Saiba mais
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={3} className="text-center funcionalidades-cards">
                        <Card style={{ width: '18rem', maxHeight: '400px', height: '100%', minHeight: '400px' }} className="custom-border">                            <Card.Img variant="top" src={estoque} style={{ width: '4rem' }} className='mx-auto m-2' />
                            <Card.Body className="d-flex flex-column ">
                                <Card.Title>Estoque</Card.Title>
                                <Card.Text id='text-black'>
                                    Gestão eficiente de produtos para atender à demanda e evitar custos desnecessários.
                                </Card.Text>
                                <Button variant="primary" className="mt-auto" onClick={() => handleShowModal([
                                    <ul className='fs-5'>
                                        <li>Movimentao de Estoque</li>
                                        <li>Entrada e Saida de Notas Fiscais</li>
                                        <li>Cadastro de Produtos</li>
                                        <li>Inventários</li>
                                    </ul>
                                ])}>Saiba mais
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={3} className="text-center funcionalidades-cards">
                        <Card style={{ width: '18rem', maxHeight: '400px', height: '100%', minHeight: '400px' }} className="custom-border">                            <Card.Img variant="top" src={vendas} style={{ width: '4rem' }} className='mx-auto m-2' />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title>Vendas</Card.Title>
                                <Card.Text id='text-black'>
                                    Gestão de vendas, pedidos de vendas e emissão de notas fiscais
                                </Card.Text>
                                <Button variant="primary" className="mt-auto" onClick={() => handleShowModal([
                                    <ul className='fs-5'>
                                        <li>Pedidos de Venda</li>
                                        <li>Consultas de vendas</li>
                                        <li>Emissão de Nota Fiscal</li>
                                    </ul>
                                ])}>Saiba mais
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xs={3} className="text-center funcionalidades-cards">
                        <Card style={{ width: '18rem', maxHeight: '400px', height: '100%', minHeight: '400px' }} className="custom-border">
                            <Card.Img variant="top" src={relatorios} style={{ width: '4rem' }} className='mx-auto m-2' />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title>Relatorios</Card.Title>
                                <Card.Text id='text-black'>
                                    Análise de dados e informações apresentadas de forma organizada e de fácil acesso.
                                </Card.Text>
                                <Button variant="primary" className="mt-auto" onClick={() => handleShowModal([
                                    <ul className='fs-5'>
                                        <li>DRE (Demonstração do resultado do exercício)</li>
                                        <li>Fluxo de Caixa</li>
                                        <li>Curva ABC</li>
                                        <li>Vendas, Estoque, Financeiro e etc</li>
                                        <li>Adaptabilidade e flexibilidade conforme sua necessidade</li>
                                    </ul>
                                ])}>Saiba mais
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-3'>Principais Funcionalidades</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                            {modalContent.map((item, index) => (
                                <ListGroup.Item key={index}>{item}</ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Modal.Body>
                </Modal>
            </Container>
        </section>
    );
};
