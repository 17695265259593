import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export const Vantagens = () => {

    const vantagensEsquerda = [
        "Redução de Custos Operacionais",
        "Aumento da Eficiência e Produtividade",
        "Melhoria na Precisão e Qualidade dos Dados Financeiros",
        "Suporte ágil sempre à sua disposição."
    ];

    const vantagensDireita = [
        "Liberação de Tempo e Recursos para Iniciativas Estratégicas",
        "Acesso Totalmente Web, Sem Instalação de Aplicativos",
        "Personalização de Acordo com as Necessidades do Cliente",
        "Sistema intuitivo de fácil aprendizagem"
    ];

    return (
        <section className='beneficios' id='beneficios'>
            <Container className='vantagens-container text-start'>
                <h3 className='fs-2 mb-3 pb-3 text-center'>
                    Benefícios em usar <span style={{ color: '#2DBC60' }}>Vialucro</span>
                </h3>
                <div className="row">
                    <div className="col">
                        <ul className='list-unstyled'>
                            {vantagensEsquerda.map((vantagem, index) => (
                                <li key={index} className='vantagens-list'>
                                    <FontAwesomeIcon icon={faCheckCircle} className='vantagens-icon' /> {vantagem}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col">
                        <ul className='list-unstyled'>
                            {vantagensDireita.map((vantagem, index) => (
                                <li key={index} className='vantagens-list'>
                                    <FontAwesomeIcon icon={faCheckCircle} className='vantagens-icon' /> {vantagem}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    )
}
